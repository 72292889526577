import { IconName, Icons, Typography } from '@sonnen/web-ui';
import clsx from 'clsx';
import { createElement, PropsWithChildren } from 'react';
import styles from './InfoBanner.module.css';

export const wuiIconSizes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;
export type WuiIconSize = (typeof wuiIconSizes)[number];

type TextInfoProps = {
  variant?: 'primary' | 'secondary';
  icon?: IconName;
  iconSize?: WuiIconSize;
};

export const InfoBanner = ({
  children,
  variant = 'primary',
  icon = 'Info',
  iconSize = 'lg',
}: TextInfoProps & PropsWithChildren) => (
  <div className={clsx(styles.infoBanner, styles[variant])} data-testid="info-banner">
    <div className={styles.iconWrapper}>
      {icon &&
        createElement(Icons[icon], {
          size: iconSize,
        })}
    </div>
    <Typography.Body2>{children}</Typography.Body2>
  </div>
);
