import clsx from 'clsx';
import styles from './CardImage.module.css';

type CardImageProps = {
  src: string;
  dataTestId: string;
  className?: string;
};

const CardImage = ({ dataTestId, src, className }: CardImageProps) => (
  <div className={clsx(styles.img, className)} data-testid={`${dataTestId}-img`}>
    <img src={src} alt={dataTestId} />
  </div>
);

export default CardImage;
