import { Alert, Battery, Card, Typography } from '@sonnen/web-ui';
import { parseHtmlTags } from '@sonnen/web-utils';
import clsx from 'clsx';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext/AppContext';
import { AppRoute } from '../../constants';
import { useTranslation } from '../../i18n/i18n';
import { OfferContext } from '../../OfferContext/OfferContext';
import { ModuleName } from '../../types';
import { moduleAvailable } from '../../utils/utils';
import { ModuleToggle } from '../ModuleToggle/ModuleToggle';
import styles from './ModuleCard.module.css';

type ModuleCardProps = {
  name: ModuleName;
  children: React.ReactNode;
  i18nScope: 'modules.priceOptimization';
  smartMeterRequired?: boolean;
};

export const ModuleCard = ({ name, children, i18nScope, smartMeterRequired }: ModuleCardProps) => {
  const { t } = useTranslation();
  const { offerLoading, offer } = useContext(OfferContext);
  const { modules, user } = useContext(AppContext);
  const selected = modules.has(name);
  const available = moduleAvailable(offer, name);

  return (
    <Card
      paddingSize="md"
      className={clsx(styles.moduleCard, {
        [styles.selected]: selected,
        [styles.unavailable]: !available,
      })}
      dataTestId={`${name}-module`}
    >
      <Typography.H2>{t(`${i18nScope}.cardTitle`)}</Typography.H2>
      <Typography.Body1>{t(`${i18nScope}.cardHeader`)}</Typography.Body1>

      {children}

      {available && (
        <div
          data-testid="module-toggle"
          className={clsx(styles.actionBox, { [styles.offerLoading]: offerLoading })}
        >
          <div className={styles.cta}>
            <Typography.Body1>{parseHtmlTags(t(`${i18nScope}.toggleCta`))}</Typography.Body1>
          </div>
          <ModuleToggle name={name} />
        </div>
      )}

      {!user && (
        <div className={styles.actionBox}>
          <div className={styles.cta}>
            <div>
              <Battery />
            </div>
            <p>
              <Typography.Label1>{t(`${i18nScope}.loginCta`)}</Typography.Label1>
              <Typography.Body1>
                <Link to={AppRoute.LOGIN}>{t(`${i18nScope}.login`)}</Link>
              </Typography.Body1>
            </p>
          </div>
        </div>
      )}

      {smartMeterRequired && (
        <Alert title={t(`${i18nScope}.smartMeterInfo.title`)}>
          {parseHtmlTags(t(`${i18nScope}.smartMeterInfo.content`))}
        </Alert>
      )}
    </Card>
  );
};
