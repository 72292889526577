import { PropsWithChildren } from 'react';
import { useAppContext } from '../../AppContext/AppContext';
import { AppRoute } from '../../constants';
import { CurrentUserSiteOptionsCreateContractFlow } from '../../graphql/generated';
import { usePathname } from '../../hooks/usePathname';
import { useOfferContext } from '../../OfferContext/OfferContext';
import { ModuleName } from '../../types';
import { moduleAvailable } from '../../utils/utils';
import SummaryCard from '../SummaryCard/SummaryCard';

const OfferSummaryTestId = ({ children }: PropsWithChildren) => (
  <div data-testid="offer-summary">{children}</div>
);

export const OfferSummary = () => {
  const { modules, user, selectedSite } = useAppContext();
  const { offer } = useOfferContext();
  const pathname = usePathname();
  const hasPriceOptimizationModule = modules.has(ModuleName.PRICE_OPTIMIZATION);
  const priceOptimizationAvailable = moduleAvailable(offer, ModuleName.PRICE_OPTIMIZATION);
  const { expectedPaymentGrossPerMonth } =
    offer?.configurationWithoutBatteryOptimizationModule?.averagePrices || {};
  const { expectedPaymentGrossPerMonth: expectedPaymentGrossPerMonthWithModule } =
    offer?.configurationWithBatteryOptimizationModule?.averagePrices || {};

  if (
    !user ||
    !selectedSite ||
    selectedSite.options.createContractFlow ===
      CurrentUserSiteOptionsCreateContractFlow.SwitchFromSonnenStromContract
  ) {
    return (
      <OfferSummaryTestId>
        <SummaryCard
          variant="sonnenBattery"
          value={expectedPaymentGrossPerMonth}
          dataTestId="summary-card-without-sonnenbattery"
        />
      </OfferSummaryTestId>
    );
  }

  const withPriceOptimizationSummaryCard = (
    <SummaryCard
      dataTestId="summary-card-with-price-optimization"
      withPriceOptimization
      value={expectedPaymentGrossPerMonthWithModule}
    />
  );

  const withoutPriceOptimizationSummaryCard = (
    <SummaryCard
      value={expectedPaymentGrossPerMonth}
      dataTestId="summary-card-without-price-optimization"
    />
  );

  if (pathname === AppRoute.OFFER_CALCULATION) {
    return (
      <OfferSummaryTestId>
        {priceOptimizationAvailable && (
          <>
            {withPriceOptimizationSummaryCard}
            <br />
          </>
        )}
        {withoutPriceOptimizationSummaryCard}
      </OfferSummaryTestId>
    );
  }

  return (
    <OfferSummaryTestId>
      {priceOptimizationAvailable && hasPriceOptimizationModule
        ? withPriceOptimizationSummaryCard
        : withoutPriceOptimizationSummaryCard}
    </OfferSummaryTestId>
  );
};
