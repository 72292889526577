import {
  AccordionList,
  Button,
  Card,
  Line,
  SectionTitle,
  Typography,
  useMobile,
} from '@sonnen/web-ui';
import { memo, useContext, useMemo } from 'react';
import { AppContext } from '../../AppContext/AppContext';
import { LocaleKey, useTranslation } from '../../i18n/i18n';
import { OfferContext } from '../../OfferContext/OfferContext';
import { ModuleName } from '../../types';
import { formatCurrency } from '../../utils/formatting';
import { moduleAvailable } from '../../utils/utils';
import OfferLoadingOrError from '../OfferLoadingOrError/OfferLoadingOrError';
import { OfferSummary } from '../OfferSummary/OfferSummary';
import StatusTag from '../StatusTag/StatusTag';
import styles from './OfferCard.module.css';

const OfferCardTitle = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.titleWithGreenLabel}>
      <Typography.H2>{t('offer.title')}</Typography.H2>
      <StatusTag text={t('offer.greenLabel')} />
    </div>
  );
};

const TariffSummary = ({ consumption }: { consumption: number }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.tariffDetails} data-testid="tariff-summary">
      <SectionTitle>{t('offerCard.yourEstimatedCosts')}</SectionTitle>
      <Typography.Body1>
        {t('offerCard.yourEstimatedCostsTagline', { consumption })}
      </Typography.Body1>
      <Line />
      <OfferSummary />
    </div>
  );
};

type OfferCardType =
  | {
      buttonLabel: string;
      onOfferBtnClick: () => void;
    }
  | {
      buttonLabel?: never;
      onOfferBtnClick?: never;
    };

const OfferCard = ({ buttonLabel, onOfferBtnClick }: OfferCardType) => {
  const { t } = useTranslation();
  const { modules } = useContext(AppContext);
  const mobile = useMobile();
  const { offer, offerLoading, offerError, deferredOfferLoading, productConfigurationsInput } =
    useContext(OfferContext);

  const configuration =
    offer?.configurationWithBatteryOptimizationModule ||
    offer?.configurationWithoutBatteryOptimizationModule;

  const { baseFeeGrossPerMonth, fixedCostsGrossPerMonth, terminationPeriodOption, averagePrices } =
    configuration || {};

  const hasPriceOptimizationModule =
    moduleAvailable(offer, ModuleName.PRICE_OPTIMIZATION) &&
    modules.has(ModuleName.PRICE_OPTIMIZATION);

  const listData = useMemo(() => {
    const listEntries: Record<string, string | null> = {
      priceDynamic: t(`offer.accordion.priceDynamic.value`),
      purchaseTariffGrossPerKwh: `${formatCurrency(averagePrices?.purchaseTariffGrossPerKwh, 'ct')}/kWh`,
      energy2Optimize: t(`offer.accordion.energy2Optimize.value`),
      baseFeeGrossPerMonth: `${formatCurrency(baseFeeGrossPerMonth, 'euro')}/${t('offer.month')}`,
      gridFees: `${formatCurrency(fixedCostsGrossPerMonth, 'euro')}/${t('offer.month')}`,
      minimumContractDuration: t(`offer.accordion.minimumContractDuration.value`),
      terminationPeriodOption: t(`offer.accordion.terminationPeriodOption.value`),
    };

    if (!hasPriceOptimizationModule) delete listEntries.energy2Optimize;

    return Object.entries(listEntries).map(([key, value]) => {
      let children = t(`offer.accordion.${key}.description` as LocaleKey);
      if (children === `offer.accordion.${key}.description`) children = '';

      return {
        label: t(`offer.accordion.${key}.label` as LocaleKey),
        value: value ?? t(`offer.accordion.${key}.value` as LocaleKey),
        children,
        dataTestId: key,
      };
    });
  }, [
    baseFeeGrossPerMonth,
    fixedCostsGrossPerMonth,
    hasPriceOptimizationModule,
    terminationPeriodOption,
    averagePrices,
    t,
  ]);

  const loading = offerLoading || deferredOfferLoading;
  const showContent = Boolean(offer && !loading && !offerError);
  const showButton = onOfferBtnClick && buttonLabel;

  return (
    <div data-testid="offer-card" id="offer-card">
      <Card paddingSize="md" className={styles.offerCard}>
        <OfferCardTitle />

        {!mobile && <OfferLoadingOrError />}

        {showContent && (
          <div className={styles.offerCardContent} data-testid="offer-card-content">
            <TariffSummary
              consumption={productConfigurationsInput.expectedElectricityConsumptionInKwh}
            />

            <AccordionList list={listData} dataTestId="offer" />

            {showButton && (
              <Button
                label={buttonLabel}
                onClick={onOfferBtnClick}
                dataTestId="offer-card-btn"
                loading={deferredOfferLoading}
                disabled={Boolean(offerError)}
                expanded
              />
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

export default memo(OfferCard);
