import { Button, Typography, useMobile } from '@sonnen/web-ui';
import { parseHtmlTags } from '@sonnen/web-utils';
import clsx from 'clsx';
import { useContext } from 'react';
import { AppContext, useAppContext } from '../../AppContext/AppContext';
import { AppRoute, nextLabelByPath, skippableRoutes } from '../../constants';
import { useOrderFlowNavigation } from '../../hooks/useOrderFlowNavigation';
import { usePathname } from '../../hooks/usePathname';
import { useTopScrollNavigate } from '../../hooks/useTopScrollNavigate';
import { useTranslation } from '../../i18n/i18n';
import { OfferContext } from '../../OfferContext/OfferContext';
import { ModuleName } from '../../types';
import { formatCurrency } from '../../utils/formatting';
import { getSelectedConfiguration, moduleAvailable } from '../../utils/utils';
import { ModuleToggle } from '../ModuleToggle/ModuleToggle';
import OfferLoadingOrError from '../OfferLoadingOrError/OfferLoadingOrError';
import styles from './MobileCta.module.css';

const DefaultCtaContent = ({ value }: { value: string }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.text} data-testid="default-cta-content">
      <Typography.Body1>{t('offer.summaryCardTitle')}</Typography.Body1>
      <span>
        <Typography.Number2>{value}</Typography.Number2>
        <Typography.Label1>{`€/${t('offer.month')}`}</Typography.Label1>
      </span>
    </div>
  );
};

const PriceOptimizationToggle = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.text} data-testid="price-optimization-toggle">
      <Typography.Body1>
        <div>{parseHtmlTags(t('modules.priceOptimization.toggleCta'))}</div>
      </Typography.Body1>
      <ModuleToggle name={ModuleName.PRICE_OPTIMIZATION} />
    </div>
  );
};

const MobileCta = () => {
  const { modules } = useAppContext();
  const { offerRequiresManualAddress } = useContext(AppContext);
  const { offer, offerLoading, deferredOfferLoading, offerError } = useContext(OfferContext);
  const { t } = useTranslation();
  const navigate = useTopScrollNavigate();
  const { next } = useOrderFlowNavigation();
  const pathname = usePathname();
  const nextLabel = t(nextLabelByPath[pathname] ?? 'offer.offerBtn');
  const mobile = useMobile();

  if (!mobile) return false;

  const loading = offerLoading || deferredOfferLoading;
  const showContent = Boolean(offer && !loading && !offerError);

  const routeWithoutValidation = skippableRoutes.has(pathname);
  const showing =
    Boolean(offer || loading || deferredOfferLoading || offerError || offerRequiresManualAddress) &&
    routeWithoutValidation;

  const showToggle =
    moduleAvailable(offer, ModuleName.PRICE_OPTIMIZATION) &&
    pathname.startsWith(AppRoute.PRICE_OPTIMIZATION);

  const { expectedPaymentGrossPerMonth } =
    getSelectedConfiguration(offer, modules)?.averagePrices || {};

  const onOfferBtnClick = () => {
    if (deferredOfferLoading || !next) return;
    navigate(next);
  };

  return (
    <div className={clsx(styles.mobileCta, { [styles.showing]: showing })} data-testid="mobile-cta">
      <OfferLoadingOrError />

      {showContent && (
        <>
          {showToggle ? (
            <PriceOptimizationToggle />
          ) : (
            <DefaultCtaContent
              value={formatCurrency(expectedPaymentGrossPerMonth, 'euro', false)}
            />
          )}

          <Button
            label={nextLabel}
            onClick={onOfferBtnClick}
            dataTestId="mobile-cta-btn"
            loading={deferredOfferLoading}
            expanded
          />
        </>
      )}
    </div>
  );
};

export default MobileCta;
