import { Alert, Card, Dropdown, InputField, Typography } from '@sonnen/web-ui';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../AppContext/AppContext';
import { EMPTY_PERSONAL_DATA } from '../../constants';
import { CurrentUserContact, CustomerUserAccountType } from '../../graphql/generated';
import { useValidation } from '../../hooks/useValidation';
import { LocaleKey, useTranslation } from '../../i18n/i18n';
import commonStyles from '../../Pages/Common.module.css';
import { TPersonalData } from '../../types';
import { businessAccount, camelToSnake, getSalutation, useSalutations } from '../../utils/utils';
import {
  emailValidator,
  nameValidator,
  noEmptySpacesValidation,
  phoneValidator,
} from '../../utils/validation';
import { IconOption, Selections } from '../Selections/Selections';
import styles from './PersonalDataSection.module.css';

const I18N_SCOPE = 'data.personalInfo';

type TInputField = keyof TPersonalData;

const defaultInputFields: {
  [key in TInputField]?: number;
} = {
  firstName: 50,
  lastName: 50,
  email: 80,
};

type PersonalDataProps = {
  onChange: (name: string, personalData: TPersonalData) => void;
  defaultValues: TPersonalData;
  revalidate: boolean;
};

const PersonalDataSection = ({
  defaultValues = EMPTY_PERSONAL_DATA,
  onChange: onChangeProp,
  revalidate,
}: PersonalDataProps) => {
  const { t } = useTranslation();
  const { user, accountType, setAccountType } = useContext(AppContext);
  const salutationOptions = useSalutations();
  const [personalData, setPersonalData] = useState<TPersonalData>(defaultValues);

  const customerTypeOptions: IconOption[] = useMemo(
    () => [
      {
        value: CustomerUserAccountType.PrivateCustomer,
        label: t(`${I18N_SCOPE}.customerType.personal`),
        icon: 'User',
      },
      {
        value: CustomerUserAccountType.BusinessCustomer,
        label: t(`${I18N_SCOPE}.customerType.business`),
        icon: 'CompanyCoordinator',
      },
    ],
    [t],
  );

  const validationSchema = useMemo(
    () => ({
      companyName: {
        required: businessAccount(accountType),
        message: t(`${I18N_SCOPE}.companyNameError`),
        validator: noEmptySpacesValidation,
      },
      salutation: {
        required: !user,
        message: t(`${I18N_SCOPE}.salutationError`),
      },
      firstName: {
        required: true,
        message: t(`${I18N_SCOPE}.firstNameError`),
        validator: nameValidator,
      },
      lastName: {
        required: true,
        message: t(`${I18N_SCOPE}.lastNameError`),
        validator: nameValidator,
      },
      email: {
        required: true,
        message: t(`${I18N_SCOPE}.emailError`),
        validator: emailValidator,
      },
      phone: {
        required: true,
        message: t(`${I18N_SCOPE}.phoneError`),
        validator: phoneValidator,
      },
    }),
    [t],
  );

  const [validate, { errors, setErrors }] = useValidation<TPersonalData>({
    values: personalData,
    schema: validationSchema,
    lazy: true,
  });

  useEffect(() => {
    if (revalidate) validate();
  }, [revalidate]);

  const resetErrors = (field: string) => setErrors({ ...errors, [field]: '' });

  const onChange = (field: string, value: string) => {
    resetErrors(field);
    const newPersonalData = { ...personalData, [field]: value };
    if (field === 'salutation') onChangeProp('personalData', newPersonalData);
    setPersonalData(newPersonalData);
  };

  const onBlur = (field: string) => {
    validate([field]);
    onChangeProp('personalData', personalData);
  };

  const generateInputField = useCallback(
    (field: TInputField) => {
      const MAX_LENGTH = 50;
      const fieldName = field as TInputField;
      const contactField = field as keyof CurrentUserContact;
      const disabled = Boolean(
        field === 'companyName' ? user?.account.companyName : user?.contact[contactField],
      );

      const fieldLabel: LocaleKey = `${I18N_SCOPE}.${field}`;

      return (
        <InputField
          key={field}
          autoComplete={field === 'email' ? 'email' : 'name'}
          dataTestId={camelToSnake(field)}
          error={errors[fieldName]}
          expanded
          inputMode={field === 'phone' ? 'tel' : 'text'}
          label={t(fieldLabel)}
          maxLength={defaultInputFields[fieldName] ?? MAX_LENGTH}
          name={field}
          onBlur={onBlur}
          onChange={onChange}
          type={field === 'email' ? 'email' : 'text'}
          value={personalData[fieldName]}
          disabled={disabled}
        />
      );
    },
    [accountType, onBlur, onChange, personalData, errors],
  );

  return (
    <Card paddingSize="md" dataTestId="personal-data-section" className={commonStyles.formSection}>
      <Typography.H3>{t(`${I18N_SCOPE}.sectionTitle`)}</Typography.H3>

      {!user && (
        <Selections
          options={customerTypeOptions}
          selected={accountType}
          onChange={(value: string) => setAccountType(value as CustomerUserAccountType)}
        />
      )}

      {businessAccount(accountType) && generateInputField('companyName')}

      {user ? (
        <Alert dataTestId="personal-data-notification">
          {t(`${I18N_SCOPE}.incompletePersonalDataNotification`)}
        </Alert>
      ) : (
        <Dropdown
          dataTestId="salutation"
          error={errors.salutation}
          label={t(`${I18N_SCOPE}.salutation`)}
          name="salutation"
          onBlur={onBlur}
          onChange={onChange}
          options={salutationOptions}
          value={getSalutation(salutationOptions, personalData.salutation!)}
        />
      )}

      {Object.keys(defaultInputFields).map((key) => generateInputField(key as TInputField))}

      <div className={styles.prefixAndPhone}>
        <InputField
          className={styles.immutableCountryPrefix}
          dataTestId="immutable-prefix"
          label={t(`${I18N_SCOPE}.prefix`)}
          disabled
          name="prefix"
          onChange={() => {}}
          readOnly
          value="+49"
        />
        <div style={{ width: '100%' }}>{generateInputField('phone')}</div>
      </div>
    </Card>
  );
};

export default PersonalDataSection;
