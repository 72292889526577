import { useEffect, useMemo, useState } from 'react';
import { PriceCards } from '../../../components/PriceCards/PriceCards';
import { HOURLY_PRICE_CHART_CURRENTLY_HOVERED } from '../../../constants';
import { useTranslation } from '../../../i18n/i18n';
import { useOfferContext } from '../../../OfferContext/OfferContext';

function isCustomEvent(event: Event): event is CustomEvent {
  return 'detail' in event;
}

export const HourlyPriceCards = () => {
  const { t } = useTranslation();
  const { hourlyPriceData } = useOfferContext();
  const [currentHourlyPrice, setCurrentHourlyPrice] = useState<number>(0);

  const onHourlyPriceHover = (event: Event) => {
    if (isCustomEvent(event) && hourlyPriceData) {
      const newIndex = parseInt(event.detail);
      if (hourlyPriceData[newIndex]) {
        const newPrice = parseFloat(hourlyPriceData[newIndex].value);
        setCurrentHourlyPrice(newPrice);
      }
    }
  };

  useEffect(() => {
    window.addEventListener(HOURLY_PRICE_CHART_CURRENTLY_HOVERED, onHourlyPriceHover);
    return () => {
      window.removeEventListener(HOURLY_PRICE_CHART_CURRENTLY_HOVERED, onHourlyPriceHover);
    };
  });

  const averagePrice = useMemo(() => {
    if (!hourlyPriceData?.length) return '';

    if (currentHourlyPrice === 0) {
      setCurrentHourlyPrice(parseFloat(hourlyPriceData[new Date().getHours()].value));
    }

    let totalPrice = 0;
    hourlyPriceData.forEach(({ value }) => (totalPrice += parseFloat(value) * 100));
    const newAverage = totalPrice / hourlyPriceData.length;

    return `<strong>${Number(newAverage.toFixed(2)).toLocaleString()}</strong> ${t('centPerKwh')}`;
  }, [hourlyPriceData, t, currentHourlyPrice]);

  const cards = useMemo(() => {
    if (!hourlyPriceData?.length) return [];

    const newCurrentHourlyPrice = Number((currentHourlyPrice * 100).toFixed(2)).toLocaleString();

    return [
      {
        title: `<strong>${newCurrentHourlyPrice}</strong> ${t('centPerKwh')}`,
        caption: t('chart.marketPrice'),
        key: 'currentPrice',
      },
      {
        title: averagePrice,
        caption: t('chart.averagePrice'),
        key: 'averagePrice',
      },
    ];
  }, [hourlyPriceData, currentHourlyPrice, t]);

  return <PriceCards cards={cards} flexRows withMargin dataTestId="hourly-price-cards" />;
};
