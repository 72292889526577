import { CurrencyPropertyMonthlySeriesPoint } from '../../../graphql/generated';
import { getDefaultLocale } from '../../../utils/utils';

const formatMonth = (dateString: string, format: 'short' | 'long'): string => {
  const date = new Date(dateString);
  return date.toLocaleString(getDefaultLocale(), { month: format });
};

const formatYear = (dateString: string): number => new Date(dateString).getFullYear();

export const getFormattedData = (data: { date: string; value: string }[]) => {
  return data
    .map((item) => ({
      shortMonth: formatMonth(item.date, 'short'),
      longMonth: formatMonth(item.date, 'long'),
      firstLetter: formatMonth(item.date, 'short').charAt(0),
      year: formatYear(item.date),
      value: parseFloat(item.value),
    }))
    .reverse();
};

export const getCurrentMonth = (): string => {
  return formatMonth(new Date().toISOString(), 'short');
};

export const getYAxisProps = (data: CurrencyPropertyMonthlySeriesPoint[]) => {
  const maxPrice = Math.ceil(Math.max(...data.map((block) => parseFloat(block.value))));
  const stepSize = maxPrice / 3;
  const stepSizeInTenths = Math.ceil(stepSize / 10) * 10;
  const ticks = Array.from(new Array(4)).map((_, i) => Math.round(stepSizeInTenths * i));

  return { ticks, domain: [0, stepSizeInTenths * 3] };
};
