/* eslint-disable react/jsx-props-no-spreading */
import { Typography, useMobile } from '@sonnen/web-ui';
import { ReactNode } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  DotProps,
  Label,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';
import { HOURLY_PRICE_CHART_CURRENTLY_HOVERED } from '../../../constants';
import { useTranslation } from '../../../i18n/i18n';
import { useOfferContext } from '../../../OfferContext/OfferContext';
import { BlueDot } from './BlueDot';
import { getHourPeriod, getTicks, getTickValues } from './HourlyPriceChart.helpers';
import styles from './HourlyPriceChart.module.css';

export const HourlyPriceChart = () => {
  const defaultIndex: number = new Date().getHours();
  const { t } = useTranslation();
  const { hourlyPriceData } = useOfferContext();
  const mobile = useMobile();

  const getTooltipContent = (props: TooltipProps<string, string>): ReactNode => {
    const { payload } = props || {};

    if (!payload?.[0]?.payload) return null;

    const timestamp = payload?.[0].payload.timestamp;

    return (
      <div className={styles.tooltipContent} data-testid="tooltip-content">
        <Typography.Label2 variant="on-colour">{getHourPeriod(timestamp)}</Typography.Label2>
      </div>
    );
  };

  const { yTicks, domain, xTicks } = getTicks(hourlyPriceData || []);
  const { centPriceData, pastData, futureData } = hourlyPriceData
    ? getTickValues(hourlyPriceData, defaultIndex)
    : {};

  const onMouseMove: CategoricalChartFunc = (e) => {
    if (!e?.activeLabel || !centPriceData) return;
    const hoveredIndex = parseInt(e.activeLabel);
    if (!isNaN(hoveredIndex) && centPriceData[hoveredIndex]) {
      window.dispatchEvent(
        new CustomEvent(HOURLY_PRICE_CHART_CURRENTLY_HOVERED, { detail: hoveredIndex }),
      );
    }
  };

  if (!hourlyPriceData) return null;

  return (
    <div data-testid="hourly-price-chart">
      <ResponsiveContainer width="100%" height={380}>
        <AreaChart
          onMouseMove={onMouseMove}
          width={630}
          height={340}
          margin={{ top: 50, right: 0, left: mobile ? -25 : 0 }}
          data={centPriceData}
        >
          <defs>
            <linearGradient
              id="fillColor"
              gradientTransform="rotate(90)"
              gradientUnits="userSpaceOnUse"
              x1="0"
              y1="0"
            >
              <stop offset="0.1" stopColor="#FC7936" stopOpacity="0.64" />
              <stop offset="0.4" stopColor="#0040FA" stopOpacity="0.08" />
            </linearGradient>
            <linearGradient id="lineColor" gradientTransform="rotate(90)">
              <stop offset="0.195435" stopColor="#FB7835" />
              <stop offset="0.8" stopColor="#0040FA" />
            </linearGradient>
          </defs>
          <Area
            type="step"
            xAxisId="futureChart"
            yAxisId="futureChart"
            fill="url(#fillColor)"
            dataKey="value"
            strokeDasharray={6}
            stroke="url(#lineColor)"
            strokeWidth={2}
            opacity={0.3}
            activeDot={(props: DotProps) => <BlueDot {...props} />}
            data={futureData}
            stackId="1"
          />
          <Area
            type="step"
            xAxisId="pastChart"
            yAxisId="pastChart"
            fill="url(#fillColor)"
            dataKey="value"
            stroke="url(#lineColor)"
            strokeWidth={2}
            opacity={0.64}
            data={pastData}
            activeDot={(props: DotProps) => <BlueDot {...props} />}
            stackId="1"
          />
          <CartesianGrid vertical={false} x={0} width={1000} />
          <XAxis strokeWidth={0} allowDataOverflow xAxisId="futureChart" hide />
          <YAxis allowDataOverflow yAxisId="futureChart" ticks={yTicks} hide domain={domain} />
          <XAxis
            xAxisId="pastChart"
            strokeWidth={0}
            dataKey="timestamp"
            tick={{ fontSize: 11, fillOpacity: 0.5 }}
            tickLine={false}
            ticks={xTicks}
            tickFormatter={(timestamp) => `${new Date(timestamp).getHours().toString()}:00`}
          />
          <YAxis
            width={0.1}
            allowDataOverflow
            yAxisId="pastChart"
            strokeWidth={0}
            type="number"
            ticks={yTicks}
            tick={{ fill: '#0A1535', opacity: 0.32, dy: 10, dx: 60, fontSize: 10 }}
            domain={domain}
          >
            <Label
              value={t('centPerKwh')}
              position="top"
              fillOpacity={0.5}
              fontSize={10}
              dx={57}
              dy={-4}
            />
          </YAxis>

          <Tooltip
            cursor={{
              stroke: '#000',
              strokeWidth: 2,
            }}
            wrapperStyle={{ width: 176 }}
            isAnimationActive={false}
            active
            defaultIndex={defaultIndex}
            content={getTooltipContent}
            offset={-58}
            position={{ y: 42 }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
