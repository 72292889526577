import { Button, Spinner, Typography, useMobile } from '@sonnen/web-ui';
import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from '../../i18n/i18n';
import { OfferContext } from '../../OfferContext/OfferContext';
import { OfferErrorTranslationKey } from '../../types';
import styles from './OfferLoadingOrError.module.css';

const OfferLoadingOrError = () => {
  const { t } = useTranslation();
  const mobile = useMobile();
  const { offerError, refreshOffer, offerLoading, deferredOfferLoading } = useContext(OfferContext);
  const loading = offerLoading || deferredOfferLoading;

  if (!loading && !offerError) return null;

  const errorKey = offerError ?? OfferErrorTranslationKey.SERVER;
  const showButton =
    errorKey !== OfferErrorTranslationKey.POSTCODE_REQUIRES_ADDRESS &&
    errorKey !== OfferErrorTranslationKey.INVALID_CUSTOMER_ADDRESS;

  return (
    <div
      data-testid="offer-loading-or-error"
      className={clsx(styles.content, {
        [styles.error]: offerError,
      })}
    >
      {loading ? (
        <div className={styles.spinnerWithText}>
          <Spinner size={mobile ? 'md' : 'lg'} />
          <Typography.Body1>{t('offer.loading')}</Typography.Body1>
        </div>
      ) : (
        <>
          <Typography.Body1>{t(`offer.${errorKey}`)}</Typography.Body1>

          {showButton && (
            <Button
              variant="secondary"
              label={t('offer.reload')}
              onClick={refreshOffer}
              dataTestId="reload-offer-btn"
              size={mobile ? 'medium' : 'large'}
              loading={loading}
              expanded
            />
          )}
        </>
      )}
    </div>
  );
};

export default OfferLoadingOrError;
