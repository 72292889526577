import { Typography } from '@sonnen/web-ui';
import clsx from 'clsx';
import leaf from './leaf.png';
import styles from './StatusTag.module.css';

interface StatusTagProps {
  text: string;
  green?: boolean;
}

const StatusTag = ({ text, green = true }: StatusTagProps) => (
  <div
    className={clsx(styles.statusTag, {
      [styles.green]: green,
      [styles.blue]: !green,
    })}
    data-testid="status-tag"
  >
    {green && (
      <img src={leaf} className={styles.leafImg} aria-label="green leaf" alt="green leaf" />
    )}
    <Typography.Label1 variant={green ? 'primary' : 'interactive'}>{text}</Typography.Label1>
  </div>
);

export default StatusTag;
