import { Layout } from '@sonnen/web-ui';
import { useAuth } from '@sonnen/web-utils/hooks';
import styles from './Home.module.css';
import HomeCard from './HomeCard/HomeCard';

const Home = () => {
  const { user: authenticatedUser } = useAuth();

  if (authenticatedUser) return null;

  return (
    <div className={styles.homePage} data-testid="home-page">
      <Layout centerVertically backgroundColor="none">
        <div className={styles.cardsWrapper}>
          <HomeCard i18nScope="customer" />
          <HomeCard i18nScope="visitor" />
        </div>
      </Layout>
    </div>
  );
};

export default Home;
