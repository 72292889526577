import {
  CreateExistingCustomerOrderAcceptedInput,
  CreateExistingCustomerSwitchingFromFlatXTariffAcceptedInput,
  CreateProductConfigurationsInput,
  CurrencyPropertyHourlySeriesPoint,
  CurrentUser,
  CurrentUserAccountBillingAddress,
  CurrentUserSite,
  CurrentUserSiteAddress,
  CustomerSalutation,
  CustomerUserAccountType,
  InputMaybe,
  ProductConfigurations,
  ProviderChangeReason,
} from './graphql/generated';

export type TFormValues = TDeliveryDetails &
  TPaymentDetails & {
    deliveryAddress: TAddress;
    personalData: TPersonalData;
  } & {
    interestedInHardwareOffer: boolean;
    billingAddress: TAddress;
  } & CreateExistingCustomerOrderAcceptedInput &
  CreateExistingCustomerSwitchingFromFlatXTariffAcceptedInput & {
    marketing?: boolean;
  };

export type SelectOptionType<T = string> = {
  label: string;
  value: T;
  additionalText?: string;
};

export type TPersonalData = {
  salutation?: CustomerSalutation;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName?: string;
};

export type TDeliveryDetails = {
  meterId: string;
  contractTerminated: boolean;
  terminatedEnergyProviderName?: string;
  terminatedEnergyProviderId?: string;
  confirmedCancellationDate?: Date;
  deliveryStartDate?: Date;
  providerChangeReason?: ProviderChangeReason;
};

export type TPaymentDetails = {
  iban: string;
  accountOwner: string;
};

export type TAddress = {
  city?: InputMaybe<string>;
  country: string;
  postalCode: string;
  streetName?: InputMaybe<string>;
  streetNumber?: InputMaybe<string>;
};

export type TCompleteAddress = {
  city: string;
  country: string;
  postalCode: string;
  streetName: string;
  streetNumber: string;
};

export type AppContextType = {
  accountType: CustomerUserAccountType;
  formValues: TFormValues;
  offerRequiresManualAddress: boolean;
  orderComplete: boolean;
  selectedSite: CurrentUserSite | null;
  setAccountType: React.Dispatch<React.SetStateAction<AppContextType['accountType']>>;
  setFormValues: React.Dispatch<React.SetStateAction<AppContextType['formValues']>>;
  setOfferRequiresManualAddress: React.Dispatch<
    React.SetStateAction<AppContextType['offerRequiresManualAddress']>
  >;
  setOrderComplete: React.Dispatch<React.SetStateAction<AppContextType['orderComplete']>>;
  setSelectedSite: React.Dispatch<React.SetStateAction<AppContextType['selectedSite']>>;
  setUser: React.Dispatch<React.SetStateAction<AppContextType['user']>>;
  setUserProcessing: React.Dispatch<React.SetStateAction<AppContextType['userProcessing']>>;
  user: CurrentUser | null;
  userProcessing: boolean;
  /**
   * The set of modules this user has selected.
   */
  modules: Set<ModuleName>;
  addModule: (module: ModuleName) => void;
  removeModule: (module: ModuleName) => void;
  toggleModule: (module: ModuleName) => void;
  customBillingAddress: boolean;
  setCustomBillingAddress: (value: boolean) => void;
};

export type UserAddress = keyof CurrentUserAccountBillingAddress | keyof CurrentUserSiteAddress;

export enum OfferErrorTranslationKey {
  SERVER = 'errorServer',
  FAILED_TO_CREATE_CONFIGURATION = 'errorConfig',
  POSTCODE_REQUIRES_ADDRESS = 'errorRequiresAddress',
  INVALID_CUSTOMER_ADDRESS = 'errorInvalidCustomerAddress',
}
export type OfferErrorTranslationKeyType = OfferErrorTranslationKey | null;

export type OfferContextType = {
  createProductConfigurations: (
    input: CreateProductConfigurationsInput,
    deferred?: boolean,
  ) => void;
  deferredOfferLoading: boolean;
  hourlyPriceData?: CurrencyPropertyHourlySeriesPoint[] | null;
  offer: ProductConfigurations | null;
  offerError: OfferErrorTranslationKeyType;
  offerLoading: boolean;
  productConfigurationsInput: CreateProductConfigurationsInput;
  refreshOffer?: () => void;
  setProductConfigurationsInput: (input: CreateProductConfigurationsInput) => void;
  personsInHousehold: number;
  setPersonsInHousehold: (persons: number) => void;
  workEmail: string;
  setWorkEmail: (value: string) => void;
};

export enum ModuleName {
  PRICE_OPTIMIZATION = 'priceOptimization',
}

export type TPriceCard = {
  title: string;
  caption: string;
  key?: string;
};
