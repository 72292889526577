import { Typography } from '@sonnen/web-ui';
import clsx from 'clsx';
import { CurrencyProperty } from '../../graphql/generated';
import { useTranslation } from '../../i18n/i18n';
import { formatCurrency } from '../../utils/formatting';
import StatusTag from '../StatusTag/StatusTag';
import styles from './SummaryCard.module.css';

type SummaryCardProps = {
  value?: CurrencyProperty;
  withPriceOptimization?: boolean;
  variant?: 'priceOptimization' | 'sonnenBattery';
  dataTestId?: string;
};

const SummaryCard = ({
  value,
  variant = 'priceOptimization',
  withPriceOptimization = false,
  dataTestId = 'summary-card',
}: SummaryCardProps) => {
  const { t } = useTranslation();

  if (!value) return null;

  return (
    <div
      className={clsx(styles.summaryCard, {
        [styles.withoutSonnenBattery]: variant === 'sonnenBattery',
      })}
      data-testid={dataTestId}
    >
      <div className={styles.content}>
        <div>
          <Typography.Label1>
            {t(withPriceOptimization ? 'summaryCard.with' : 'summaryCard.without')}
          </Typography.Label1>{' '}
          {variant === 'sonnenBattery' && <br />}
          <Typography.Body1>{t(`summaryCard.${variant}`)}</Typography.Body1>
        </div>
        {withPriceOptimization && <StatusTag text={t('summaryCard.recommended')} green={false} />}
      </div>

      <div className={styles.valueCurrencyWrapper}>
        <Typography.Number3>{formatCurrency(value, 'euro', false)}</Typography.Number3>
        <Typography.Label1>€/{t('offer.month')}</Typography.Label1>
      </div>
    </div>
  );
};

export default SummaryCard;
