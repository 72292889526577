import { CurrencyPropertyHourlySeriesPoint } from '../../../graphql/generated';

export const getHourString = (timestamp: string) => {
  const date = new Date(timestamp);
  return `${date.getHours().toString().padStart(2, '0')}:00`;
};

const generateYTicks = (min: number, max: number) => {
  const result = [];
  for (let i = Math.ceil(min / 10) * 10; i <= Math.ceil(max / 10) * 10; i += 10) {
    result.push(i);
  }
  return result;
};

export const getTicks = (
  data?: CurrencyPropertyHourlySeriesPoint[] | undefined,
): {
  yTicks: number[];
  domain: [number, number];
  xTicks: string[];
} => {
  if (!data) {
    return { yTicks: [], domain: [0, 0], xTicks: [] };
  }

  const maxPrice = Math.ceil(Math.max(...data.map((block) => parseFloat(block.value) * 100)));
  const minPrice = Math.floor(Math.min(...data.map((block) => parseFloat(block.value) * 100)));
  const yTicks = generateYTicks(minPrice, maxPrice);
  const xTicks =
    data && data.length === 24 ? [1, 6, 11, 17, 22].map((hour) => data[hour].timestamp) : [];
  const domain: [number, number] = [Math.floor(minPrice / 10) * 10, Math.ceil(maxPrice / 10) * 10];
  return { yTicks, domain, xTicks };
};

export const getNextHour = (timestamp: string) => {
  const date = new Date(timestamp);
  date.setHours(date.getHours() + 1);
  return date;
};

export const getHourPeriod = (timestamp: string) => {
  const nextHour = getNextHour(timestamp);
  return `${getHourString(timestamp)} - ${getHourString(nextHour.toISOString())}`;
};

export const getDataWithCentPrices = (
  hourlyPurchaseTariffGrossPerKwhSeries: CurrencyPropertyHourlySeriesPoint[],
) => {
  return hourlyPurchaseTariffGrossPerKwhSeries.map((item) => ({
    ...item,
    value: parseFloat(item.value) * 100,
  }));
};

export const getTickValues = (
  hourlyPurchaseTariffGrossPerKwhSeries: CurrencyPropertyHourlySeriesPoint[],
  defaultIndex: number,
) => {
  const centPriceData = getDataWithCentPrices(hourlyPurchaseTariffGrossPerKwhSeries);

  const pastData = centPriceData.map((item, index) =>
    index <= defaultIndex
      ? item
      : {
          ...item,
          value: null,
        },
  );

  const futureData = centPriceData.map((item, index) =>
    index >= defaultIndex
      ? item
      : {
          ...item,
          value: null,
        },
  );
  return { pastData, futureData, centPriceData };
};
