import { Button, LanguageDropdown, Layout, Link, Typography, useMobile } from '@sonnen/web-ui';
import clsx from 'clsx';
import { memo, useContext } from 'react';
import { AppContext } from '../../AppContext/AppContext';
import { AppRoute, noBottomCtaRoutes } from '../../constants';
import { usePathname } from '../../hooks/usePathname';
import { useTranslation } from '../../i18n/i18n';
import { SupportedLanguage } from '../../i18n/types';
import { OfferContext } from '../../OfferContext/OfferContext';
import styles from './Footer.module.css';

const languages: { label: string; value: SupportedLanguage }[] = [
  { label: 'Deutsch (DE)', value: 'de' },
  { label: 'English (UK)', value: 'en-GB' },
];

type FooterProps = {
  openCookieBanner: () => void;
};

const Footer = ({ openCookieBanner }: FooterProps) => {
  const { t, language, changeLanguage } = useTranslation();
  const mobile = useMobile();
  const { offerRequiresManualAddress } = useContext(AppContext);
  const { offer, offerLoading, deferredOfferLoading } = useContext(OfferContext);
  const pathname = usePathname();
  const bottomSpace =
    (offer || offerLoading || deferredOfferLoading || offerRequiresManualAddress) &&
    mobile &&
    !noBottomCtaRoutes.includes(pathname);

  const lightVariant = !mobile && pathname === AppRoute.ROOT;
  const variant = lightVariant ? 'on-colour' : 'secondary';

  return (
    <footer
      className={clsx(styles.footer, { [styles.spaceForMobileOffer]: bottomSpace })}
      data-testid="footer"
      aria-label="footer"
    >
      <Layout centerVertically backgroundColor="none">
        <div className={styles.footerItems}>
          <div className={styles.sonnen} data-testid="copyright">
            <Typography.Label2 variant={variant}>{t('footer.sonnen')}</Typography.Label2>
          </div>

          <div className={styles.language}>
            <LanguageDropdown<SupportedLanguage>
              title={t('footer.changeLanguage')}
              languages={languages}
              language={language}
              changeLanguage={changeLanguage}
              lightVariant={!mobile && pathname === AppRoute.ROOT}
            />
          </div>

          <div className={styles.links}>
            <Link href="https://sonnen.de/agb" target="_blank" dataTestId="terms-of-use-link">
              <Typography.Label2 variant={variant}>{t('footer.termsOfUse')}</Typography.Label2>
            </Link>

            <Button
              variant="tertiary"
              size="small"
              label="Cookies"
              dataTestId="adjust-cookie-consent-btn"
              onClick={openCookieBanner}
              className={clsx(styles.cookiesBtn, { [styles.light]: lightVariant })}
            />

            <Link href="https://sonnen.de/impressum" target="_blank" dataTestId="imprint">
              <Typography.Label2 variant={variant}>{t('footer.imprint')}</Typography.Label2>
            </Link>
          </div>
        </div>
      </Layout>
    </footer>
  );
};

export default memo(Footer);
