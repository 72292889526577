import styles from './CheckAnimation.module.css';

export const CheckAnimation = () => (
  <div className={styles.checkAnimation} data-testid="check-animation">
    <svg
      width="362"
      height="361"
      viewBox="0 0 362 361"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.48" cx="181.958" cy="181.458" r="175.686" fill="#CCD9FE" />
      <circle opacity="0.24" cx="181.958" cy="181.457" r="117.124" fill="#99B3FD" />
      <circle opacity="0.16" cx="181.958" cy="181.457" r="73.2026" fill="#668CFC" />
      <g filter="url(#filter0_d_25462_162557)">
        <path
          d="M181.957 219.889C203.308 219.889 220.388 202.808 220.388 181.458C220.388 160.107 203.308 143.026 181.957 143.026C160.606 143.026 143.525 160.107 143.525 181.458C143.525 202.808 160.606 219.889 181.957 219.889Z"
          fill="url(#paint0_linear_25462_162557)"
        />
        <g filter="url(#filter1_d_25462_162557)">
          <path
            d="M174.466 197.331C175.321 198.612 176.602 199.039 177.883 199.039C179.164 199.039 180.445 198.612 181.299 197.331L200.514 171.71C201.795 170.002 201.368 167.013 199.66 165.732C197.952 164.451 194.963 164.878 193.682 166.586L177.883 187.51L174.894 183.667C173.612 181.959 170.623 181.532 168.915 182.813C167.207 184.094 166.78 187.083 168.061 188.791L174.466 197.331Z"
            fill="url(#paint1_linear_25462_162557)"
          />
          <path
            d="M174.466 197.331C175.321 198.612 176.602 199.039 177.883 199.039C179.164 199.039 180.445 198.612 181.299 197.331L200.514 171.71C201.795 170.002 201.368 167.013 199.66 165.732C197.952 164.451 194.963 164.878 193.682 166.586L177.883 187.51L174.894 183.667C173.612 181.959 170.623 181.532 168.915 182.813C167.207 184.094 166.78 187.083 168.061 188.791L174.466 197.331Z"
            fill="url(#paint2_radial_25462_162557)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_25462_162557"
          x="126.035"
          y="127.536"
          width="111.844"
          height="111.843"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0392157 0 0 0 0 0.0823529 0 0 0 0 0.207843 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_25462_162557"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_25462_162557"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_25462_162557"
          x="155.316"
          y="154.987"
          width="57.9434"
          height="58.0518"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0392157 0 0 0 0 0.0823529 0 0 0 0 0.207843 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_25462_162557"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_25462_162557"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_25462_162557"
          x1="143.525"
          y1="181.458"
          x2="220.388"
          y2="181.458"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3F5FF" />
          <stop offset="1" stopColor="#FFF7F3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_25462_162557"
          x1="184.288"
          y1="164.987"
          x2="184.288"
          y2="199.039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA5602" />
          <stop offset="1" stopColor="#0040FA" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_25462_162557"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(184.702 164.987) rotate(90) scale(33.8562 33.7477)"
        >
          <stop stopColor="#E93548" stopOpacity="0.2" />
          <stop offset="1" stopColor="#E93548" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  </div>
);
