import { FilterChips } from '@sonnen/web-ui';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../AppContext/AppContext';
import { LocaleKey, useTranslation } from '../../../i18n/i18n';
import { useOfferContext } from '../../../OfferContext/OfferContext';
import { ModuleName } from '../../../types';
import { ChartLegend } from '../ChartLegend/ChartLegend';
import { EnergyDynamicUSP } from '../EnergyDynamicUSP/EnergyDynamicUSP';
import { HourlyPriceCards } from '../HourlyPriceCards/HourlyPriceCards';
import { HourlyPriceChart } from '../HourlyPriceChart/HourlyPriceChart';
import { MonthlyPriceChart } from '../MonthlyPriceChart/MonthlyPriceChart';
import styles from './ChartsContainer.module.css';

type ChartType = 'today' | 'pastYear';

const TEST_ID = 'charts-container';

export const ChartsContainer = () => {
  const { t } = useTranslation();
  const { modules } = useAppContext();
  const { offer, hourlyPriceData } = useOfferContext();
  const chartDataPresent = Boolean(hourlyPriceData);

  const [selectedChart, setSelectedChart] = useState<ChartType>('today');

  useEffect(() => {
    if (!offer) setSelectedChart('today');
  }, [offer]);

  let legend: LocaleKey = 'chart.hourlyPriceWithEnergyDynamic';

  if (hourlyPriceData === null) {
    legend = 'home.cardChart.legend';
  } else if (chartDataPresent && selectedChart === 'pastYear') {
    if (
      modules.has(ModuleName.PRICE_OPTIMIZATION) &&
      offer?.configurationWithBatteryOptimizationModule
    ) {
      legend = 'chart.monthlyWithPriceOptimizationLegend';
    } else {
      legend = 'chart.monthlyWithoutPriceOptimizationLegend';
    }
  }

  const tabs = [
    {
      value: 'today',
      label: t('chart.today'),
    },
    {
      value: 'pastYear',
      label: t('chart.pastYear'),
    },
  ];

  if (hourlyPriceData === null) {
    return (
      <>
        <EnergyDynamicUSP />
        <ChartLegend localeKey={legend} />
      </>
    );
  }

  if (typeof hourlyPriceData === 'undefined') return <div data-testid={TEST_ID} />;

  return (
    <div data-testid={TEST_ID}>
      {selectedChart === 'today' ? (
        <div data-testid="hourly-price-chart-container">
          <HourlyPriceCards />
          <HourlyPriceChart />
        </div>
      ) : (
        <MonthlyPriceChart />
      )}

      <div className={styles.chartLabels}>
        <FilterChips
          multiple={false}
          items={tabs}
          onChange={(item) => setSelectedChart(item[0].value as ChartType)}
          stretch
          required
        />
      </div>
      <ChartLegend localeKey={legend} />
    </div>
  );
};
