import { Typography } from '@sonnen/web-ui';
import { parseHtmlTags } from '@sonnen/web-utils';
import classNames from 'classnames';
import { TPriceCard } from '../../types';
import styles from './PriceCards.module.css';

type PriceCardsProps = {
  cards: TPriceCard[];
  withMargin?: boolean;
  dataTestId?: string;
  flexRows?: boolean;
};

export const PriceCards = ({ cards, withMargin, dataTestId, flexRows }: PriceCardsProps) => (
  <div
    className={classNames(styles.priceCards, {
      [styles.margin]: withMargin,
      [styles.flexRows]: flexRows,
    })}
    data-testid={dataTestId}
  >
    {cards.map((card) => (
      <div
        className={styles.priceCard}
        key={card.key || card.title}
        data-testid={`price-card-${card.key}`}
      >
        <Typography.H3>{parseHtmlTags(card.title)}</Typography.H3>
        <Typography.Body2>{card.caption}</Typography.Body2>
      </div>
    ))}
  </div>
);
