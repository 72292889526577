import { Alert, Button, Typography } from '@sonnen/web-ui';
import { useAuth } from '@sonnen/web-utils/hooks';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import CheckmarkLines from '../../../components/CheckmarkLines/CheckmarkLines';
import { CurrentUser } from '../../../graphql/generated';
import { useTranslation } from '../../../i18n/i18n';
import OfferCalculationForm from '../../OfferCalculation/OfferCalculationForm/OfferCalculationForm';
import styles from './HomeCard.module.css';

export type TranslationsScope = 'customer' | 'visitor';

type HomeCardProps = {
  i18nScope: TranslationsScope;
};

const HomeCard = ({ i18nScope }: HomeCardProps) => {
  const { t } = useTranslation();
  const { login, error } = useAuth<CurrentUser>();
  const [showForm, setShowForm] = useState(false);
  const [showComponentInDom, setShowComponentInDom] = useState(false);
  const customer = i18nScope === 'customer';

  useEffect(() => {
    setTimeout(() => setShowForm(showComponentInDom), 50);
  }, [showComponentInDom]);

  return (
    <div
      className={clsx(styles.homeCard, { [styles.individualHeight]: customer })}
      data-testid={`${i18nScope}-card`}
    >
      <div className={styles.content}>
        <span>
          <Typography.H2>{t(`login.${i18nScope}Card.title`)}</Typography.H2>
          <CheckmarkLines list={t(`login.${i18nScope}Card.bulletPoints`).split('\n')} />
        </span>

        <div className={styles.errorAndButton}>
          {error && customer && (
            <div className={styles.error}>
              <Alert variant="error" dataTestId={`${i18nScope}-error-notification`}>
                {t('loginError')}
              </Alert>
            </div>
          )}

          {!showComponentInDom && (
            <Button
              variant={customer ? 'primary' : 'secondary'}
              iconRight={customer ? 'ExternalLink' : undefined}
              label={t(`login.${i18nScope}Card.button`)}
              dataTestId={`login-${i18nScope}-card-btn`}
              onClick={customer ? login : () => setShowComponentInDom(true)}
              expanded
            />
          )}
        </div>

        {!customer && showComponentInDom && (
          <div
            className={clsx(styles.formAnimation, { [styles.showForm]: showForm })}
            data-testid="calculation-form"
          >
            <OfferCalculationForm />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeCard;
