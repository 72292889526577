import DOMPurify from 'dompurify';
import {
  CreateCustomerOrderPaymentDetailsInput,
  CreateExistingCustomerOrderDeliveryDetailsInput,
  CreateExistingCustomerOrderInput,
  CreateExistingCustomerOrderPersonalDataInput,
  CreateExistingCustomerSwitchingFromFlatXTariffOrderDeliveryDetailsInput,
  CreateExistingCustomerSwitchingFromFlatXTariffOrderInput,
  CreateNewCustomerOrderDeliveryDetailsInput,
  CreateNewCustomerOrderInput,
  CreateNewCustomerOrderPersonalDataInput,
  CurrentUser,
  ProductConfigurationWithBatteryOptimizationModule,
  ProductConfigurationWithoutBatteryOptimizationModule,
} from '../../graphql/generated';
import { TFormValues } from '../../types';
import {
  completeAddress,
  formatDateYYYYMMDD,
  getActualBillingAddress,
  getActualDeliveryStartDate,
  removeLeadingZero,
} from '../../utils/utils';

const getPersonalData = <
  T = CreateExistingCustomerOrderPersonalDataInput | CreateNewCustomerOrderPersonalDataInput,
>(
  formValues: TFormValues,
  user: CurrentUser | null = null,
): T => {
  const { firstName, lastName, email, phone, companyName } = formValues.personalData;

  const personalDataInput: Partial<CreateNewCustomerOrderPersonalDataInput> = {
    phone: user?.contact.phone ?? `+49 ${removeLeadingZero(phone)}`,
    email,
    firstName,
    lastName,
  };

  if (companyName) personalDataInput.companyName = DOMPurify.sanitize(companyName);

  if (!user) {
    return {
      ...personalDataInput,
      salutation: formValues.personalData.salutation,
    } as T;
  }

  return personalDataInput as T;
};

const getOrderMutationInput = (
  formValues: TFormValues,
  configuration:
    | ProductConfigurationWithoutBatteryOptimizationModule
    | ProductConfigurationWithBatteryOptimizationModule,
  consumptionEstimated: number,
) => {
  const { productEngineConfigUrl } = configuration;
  const {
    interestedInHardwareOffer,
    confirmedCancellationDate,
    contractTerminated,
    deliveryAddress,
    billingAddress,
    bindingContract,
  } = formValues;

  const deliveryDetails: Omit<CreateNewCustomerOrderDeliveryDetailsInput, 'providerChangeReason'> =
    {
      consumptionEstimated,
      contractTerminated: formValues.contractTerminated,
      meterId: formValues.meterId,
      productEngineConfigUrl,
      terminatedEnergyProviderName: null,
      terminatedEnergyProviderId: null,
      confirmedCancellationDate: null,
      deliveryStartDate: formatDateYYYYMMDD(getActualDeliveryStartDate(formValues)),
    };

  if (contractTerminated) {
    deliveryDetails.confirmedCancellationDate = formatDateYYYYMMDD(confirmedCancellationDate);
  } else {
    deliveryDetails.terminatedEnergyProviderName = formValues.terminatedEnergyProviderName ?? '';
    deliveryDetails.terminatedEnergyProviderId = formValues.terminatedEnergyProviderId ?? '';
  }

  const paymentDetails: CreateCustomerOrderPaymentDetailsInput = {
    iban: formValues.iban.replace(/\s/g, ''),
    accountOwner: formValues.accountOwner,
    workEmail: '',
  };

  return {
    deliveryAddress: completeAddress(deliveryAddress),
    deliveryDetails,
    paymentDetails,
    accepted: {
      paymentAuthorization: formValues.paymentAuthorization,
      termsAndConditions: formValues.termsAndConditions,
      ...(bindingContract && {
        bindingContract: true,
      }),
    },
    billingAddress: completeAddress(billingAddress),
    ...(interestedInHardwareOffer && {
      interestedInHardwareOffer: true,
    }),
  };
};

export const getOrderMutationInputNewCustomer = (
  formValues: TFormValues,
  configuration: ProductConfigurationWithoutBatteryOptimizationModule,
  consumptionEstimated: number,
  customBillingAddress: boolean,
  workEmail: string,
): CreateNewCustomerOrderInput => {
  const input = getOrderMutationInput(formValues, configuration, consumptionEstimated);
  const personalData = getPersonalData<CreateNewCustomerOrderPersonalDataInput>(formValues);
  return {
    ...input,
    deliveryDetails: {
      ...input.deliveryDetails,
      providerChangeReason: formValues.providerChangeReason!,
    },
    personalData,
    billingAddress: getActualBillingAddress(formValues, customBillingAddress),
    accepted: { ...input.accepted, marketing: Boolean(formValues.marketing) },
    paymentDetails: { ...input.paymentDetails, workEmail },
  };
};

export type CustomerOrderMutationOptions = {
  user: CurrentUser | null;
  formValues: TFormValues;
  configuration:
    | ProductConfigurationWithoutBatteryOptimizationModule
    | ProductConfigurationWithBatteryOptimizationModule;
  consumptionEstimated: number;
  batteryId: string | undefined;
  siteId: string;
  workEmail: string;
};

export const getOrderMutationInputExistingCustomer = (
  options: CustomerOrderMutationOptions,
): CreateExistingCustomerOrderInput => {
  const { user, formValues, configuration, consumptionEstimated, batteryId, siteId, workEmail } =
    options;
  const input = getOrderMutationInput(formValues, configuration, consumptionEstimated);
  const personalData = getPersonalData<CreateExistingCustomerOrderPersonalDataInput>(
    formValues,
    user,
  );

  const deliveryDetails: CreateExistingCustomerOrderDeliveryDetailsInput = {
    ...input.deliveryDetails,
    batteryId: batteryId || null,
    siteId: siteId || '',
  };

  const paymentDetails: CreateCustomerOrderPaymentDetailsInput = {
    ...input.paymentDetails,
    workEmail,
  };

  return {
    ...input,
    personalData,
    deliveryDetails,
    paymentDetails,
  };
};

export const getOrderMutationInputChangingFromExistingTariff = (
  options: CustomerOrderMutationOptions,
): CreateExistingCustomerSwitchingFromFlatXTariffOrderInput => {
  const { user, formValues, configuration, consumptionEstimated, batteryId, siteId, workEmail } =
    options;
  const input = getOrderMutationInput(formValues, configuration, consumptionEstimated);
  const personalData = getPersonalData<CreateExistingCustomerOrderPersonalDataInput>(
    formValues,
    user,
  );

  const deliveryDetails: CreateExistingCustomerSwitchingFromFlatXTariffOrderDeliveryDetailsInput = {
    batteryId: batteryId || null,
    consumptionEstimated,
    productEngineConfigUrl: configuration.productEngineConfigUrl,
    siteId,
  };

  const paymentDetails: CreateCustomerOrderPaymentDetailsInput = {
    ...input.paymentDetails,
    workEmail,
  };

  return {
    ...input,
    personalData,
    deliveryDetails,
    paymentDetails,
    ...(formValues.interestedInHardwareOffer && {
      interestedInHardwareOffer: true,
    }),
  };
};
