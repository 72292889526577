import { getLocale } from '@sonnen/web-utils';
import { ChangeEvent } from 'react';
import { CurrencyProperty } from '../graphql/generated';

/**
 * Converts a stringed number to a two digit string.
 */
export const getTwoDigitsString = (value: string | number): string => {
  return parseFloat(value.toString().replace(',', '.')).toLocaleString(getLocale(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

/**
 * Formats amounts into cents or euros according to the German currency format.
 * @param currencyObject Object with currency and value or an unknown (null or undefined) param
 * @param symbol Optional boolean to return value with currency symbol.
 * @returns a formatted string, with or without a currency symbol or an em-dash as placeholder for missing value
 * Formats value to euro or to cents for amounts smaller than 1 euro.
 */
export const formatCurrency = (
  currencyObject: CurrencyProperty | null | undefined,
  division: 'euro' | 'ct',
  withSymbol = true,
): string => {
  if (!currencyObject) return ' — ';

  const symbols = {
    euro: '€',
    ct: 'ct',
  };
  const symbol = withSymbol ? symbols[division] : '';

  const { value } = currencyObject;
  let formattedValue = getTwoDigitsString(value);

  if (Number(value) < 1) {
    const order = division === 'ct' ? 100 : 1;
    formattedValue = getTwoDigitsString((parseFloat(value) * order).toString());
  }

  return `${formattedValue}${symbol ? ` ${symbol}` : ''}`;
};

/**
 * Formats IBAN to have space every 4 characters.
 * @param iban DE02120300000000202051
 * @returns Formatted IBAN to be displayed in the input field: DE02 1203 0000 0000 2020 51
 */
export const formatIBAN = (iban: string): string => {
  const trimmedIban = iban.replace(/\s+/g, '');
  return trimmedIban
    .replace(/[^\dA-Za-z]/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
};

/**
 * This function calculates and keeps the position of the cursor when editing the IBAN formatted with spaces.
 * @param e The event received from the input field.
 */
export const formatIbanWithCursorPosition = (e: ChangeEvent<HTMLInputElement>) => {
  const { target } = e;
  let position = target.selectionEnd;
  const { length } = target.value;

  if (position) {
    target.value = formatIBAN(target.value);
    position +=
      target.value.charAt(position - 1) === ' ' &&
      target.value.charAt(length - 1) === ' ' &&
      length !== target.value.length
        ? 1
        : 0;
    target.selectionEnd = position;
  }
};

export const getCurrencyPropertyValue = (input: CurrencyProperty | null | undefined): number => {
  if (!input) return 0;
  return parseFloat(input.value.replace(',', '.'));
};
