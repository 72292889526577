import { Alert, Button, Card } from '@sonnen/web-ui';
import clsx from 'clsx';
import { useContext, useEffect, useMemo } from 'react';
import { AppContext } from '../../AppContext/AppContext';
import { DataRow } from '../../components/DataRow/DataRow';
import { DataSection } from '../../components/DataSection/DataSection';
import { OrderFlowPage } from '../../components/OrderFlowPage/OrderFlowPage';
import {
  CustomerSalutation,
  ProductConfigurations,
  ProductConfigurationWithBatteryOptimizationModule,
  ProductConfigurationWithoutBatteryOptimizationModule,
  useCreateExistingCustomerChangingFromFlatXTariffOrderMutation,
  useCreateExistingCustomerOrderMutation,
  useCreateNewCustomerOrderMutation,
} from '../../graphql/generated';
import { useOrderFlowNavigation } from '../../hooks/useOrderFlowNavigation';
import { useTopScrollNavigate } from '../../hooks/useTopScrollNavigate';
import { useTranslation } from '../../i18n/i18n';
import { OfferContext } from '../../OfferContext/OfferContext';
import {
  ModuleName,
  TAddress,
  TDeliveryDetails,
  TPaymentDetails,
  TPersonalData,
} from '../../types';
import {
  getActualBillingAddress,
  getActualDeliveryStartDate,
  getBatteryId,
  getSalutation,
  getSwitchFromExistingContract,
  localeDateString,
  useSalutations,
} from '../../utils/utils';
import { visitorPersonalDataValidator } from '../../utils/validation';
import {
  CustomerOrderMutationOptions,
  getOrderMutationInputChangingFromExistingTariff,
  getOrderMutationInputExistingCustomer,
  getOrderMutationInputNewCustomer,
} from './DataReview.helpers';
import styles from './DataReview.module.css';

const getSelectedConfiguration = (
  modules: Set<ModuleName>,
  offer: ProductConfigurations,
):
  | ProductConfigurationWithoutBatteryOptimizationModule
  | ProductConfigurationWithBatteryOptimizationModule =>
  modules.has(ModuleName.PRICE_OPTIMIZATION) && offer.configurationWithBatteryOptimizationModule
    ? offer.configurationWithBatteryOptimizationModule
    : offer.configurationWithoutBatteryOptimizationModule;

const DataReview = () => {
  const { t } = useTranslation();
  const navigate = useTopScrollNavigate();
  const salutationOptions = useSalutations();
  const {
    offer,
    productConfigurationsInput: productConfigurationInput,
    workEmail,
  } = useContext(OfferContext);

  const {
    modules,
    user,
    formValues,
    setOrderComplete,
    orderComplete,
    selectedSite,
    customBillingAddress,
  } = useContext(AppContext);
  const { contractTerminated } = formValues;
  const { previous, next } = useOrderFlowNavigation();
  const switchFromExistingContract = getSwitchFromExistingContract(selectedSite);

  const deliveryDetailsFields: (keyof TDeliveryDetails)[] = ['meterId'];

  if (contractTerminated) {
    deliveryDetailsFields.push('confirmedCancellationDate');
  } else {
    deliveryDetailsFields.push('terminatedEnergyProviderName');
    deliveryDetailsFields.push('deliveryStartDate');
  }

  const [createNewCustomerOrderMutation, { data: dataNew, loading: loadingNew, error: errorNew }] =
    useCreateNewCustomerOrderMutation();

  const [
    createExistingCustomerOrderMutation,
    { data: dataExisting, loading: loadingExisting, error: errorExisting },
  ] = useCreateExistingCustomerOrderMutation();

  const [
    createExistingCustomerChangingFromFlatXTariffOrderMutation,
    { data: dataSwitchFromFlatX, loading: loadingSwitchFromFlatX, error: errorSwitchFromFlatX },
  ] = useCreateExistingCustomerChangingFromFlatXTariffOrderMutation();

  const loading = loadingExisting || loadingNew || loadingSwitchFromFlatX;

  const createOrder = () => {
    if (loading || orderComplete || !offer) return;

    if (user) {
      const options: CustomerOrderMutationOptions = {
        user,
        formValues,
        configuration: getSelectedConfiguration(modules, offer),
        consumptionEstimated: productConfigurationInput.expectedElectricityConsumptionInKwh,
        batteryId: getBatteryId(selectedSite),
        siteId: selectedSite?.id ?? '',
        workEmail,
      };

      if (switchFromExistingContract) {
        const input = getOrderMutationInputChangingFromExistingTariff(options);
        createExistingCustomerChangingFromFlatXTariffOrderMutation({ variables: { input } });
      } else {
        const input = getOrderMutationInputExistingCustomer(options);
        createExistingCustomerOrderMutation({ variables: { input } });
      }
    } else {
      if (!visitorPersonalDataValidator(formValues.personalData)) return;

      const input = getOrderMutationInputNewCustomer(
        formValues,
        offer.configurationWithoutBatteryOptimizationModule,
        productConfigurationInput.expectedElectricityConsumptionInKwh,
        customBillingAddress,
        workEmail,
      );
      createNewCustomerOrderMutation({ variables: { input } });
    }
  };

  useEffect(() => {
    const successfulCustomerOrder = dataExisting?.createExistingCustomerOrder && !errorExisting;
    const successfulSwitchToFlatXOrder =
      dataSwitchFromFlatX?.createExistingCustomerSwitchingFromFlatXTariffOrder &&
      !errorSwitchFromFlatX;
    const successfulVisitorOrder = dataNew?.createNewCustomerOrder && !errorNew;

    if (successfulCustomerOrder || successfulVisitorOrder || successfulSwitchToFlatXOrder) {
      setOrderComplete(true);
      navigate(next!);
    }
  }, [dataExisting, dataNew, dataSwitchFromFlatX]);

  const personalData = useMemo(() => {
    let values = formValues.personalData;
    const fields: (keyof TPersonalData)[] = ['firstName', 'lastName', 'email', 'phone'];

    if (!user) {
      fields.unshift('salutation');
      const salutation = getSalutation(salutationOptions, formValues.personalData.salutation!);
      values = { ...values, salutation: salutation?.label as CustomerSalutation };
    }
    if (values.companyName) fields.unshift('companyName');
    return { fields, values };
  }, [user]);

  const showErrorNotification = (errorExisting || errorNew) && !loading;

  const actualBillingAddress = getActualBillingAddress(
    formValues,
    customBillingAddress || Boolean(user),
  );

  return (
    <OrderFlowPage dataTestId="data-review" title={t('review.title')} className={styles.dataReview}>
      <DataSection<TPersonalData>
        fields={personalData.fields}
        i18nScope="data.personalInfo"
        dataTestId="personal-info"
        source={personalData.values}
      />

      <DataSection<TAddress>
        fields={['streetName', 'streetNumber', 'postalCode', 'city']}
        dataTestId="delivery-address"
        i18nScope="data.address"
        source={formValues.deliveryAddress}
        name="deliveryAddress"
      />

      <DataSection<TAddress>
        fields={['streetName', 'streetNumber', 'postalCode', 'city']}
        dataTestId="billing-address"
        i18nScope="data.address"
        source={actualBillingAddress}
        name="billingAddress"
      />

      {!switchFromExistingContract && (
        <DataSection<TDeliveryDetails>
          fields={deliveryDetailsFields}
          i18nScope="review.deliveryDetails"
          dataTestId="delivery-details"
          source={formValues}
        >
          {!user && formValues.providerChangeReason && (
            <DataRow
              label={t('review.deliveryDetails.providerChangeReason')}
              value={t(`data.providerChangeReason.${formValues.providerChangeReason}.label`)}
              dataTestId="reason-for-change"
            />
          )}
          {contractTerminated && (
            <DataRow
              label={t('review.deliveryDetails.deliveryStartDate')}
              value={localeDateString(getActualDeliveryStartDate(formValues))}
              dataTestId="projected-delivery-start-date"
            />
          )}
        </DataSection>
      )}

      <DataSection<TPaymentDetails>
        fields={['iban', 'accountOwner']}
        i18nScope="data.paymentDetails"
        dataTestId="payment-details"
        source={formValues}
      />

      <Card paddingSize="md">
        {(loading || orderComplete || showErrorNotification) && (
          <div className={styles.notifications}>
            {loading && (
              <Alert variant="info" dataTestId="loading-notification">
                {t('review.placingOrder')}
              </Alert>
            )}
            {orderComplete && (
              <Alert variant="success" dataTestId="order-complete-notification">
                {t('review.orderComplete')}
              </Alert>
            )}
            {showErrorNotification && (
              <Alert variant="error" dataTestId="error-notification">
                {t('review.orderError')}
              </Alert>
            )}
          </div>
        )}

        <div className={clsx(styles.buttons)}>
          <Button
            className={styles.editBtn}
            variant="secondary"
            label={t('review.editData')}
            onClick={() => navigate(previous!, { state: 'data-review' })}
            dataTestId="edit-data-btn"
            disabled={loading}
            expanded
          />

          <Button
            className={styles.orderBtn}
            size="large"
            label={t('review.orderNow')}
            onClick={createOrder}
            loading={loading}
            disabled={orderComplete}
            dataTestId="purchase"
            expanded
          />
        </div>
      </Card>
    </OrderFlowPage>
  );
};

export default DataReview;
