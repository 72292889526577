import { DotProps } from 'recharts';

export const BlueDot = (props: DotProps) => {
  if (!props.cx || !props.cy) return null;

  return (
    <svg
      x={props.cx - 12}
      y={props.cy - 12}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="blue-dot"
    >
      <circle opacity="0.2" cx="12.5" cy="12" r="8" fill="#0040FA" />
      <circle opacity="0.2" cx="12.5" cy="12" r="12" fill="#0040FA" />
      <circle cx="12.5" cy="12" r="4.5" fill="#0040FA" stroke="white" />
    </svg>
  );
};
