import SmartMeter from '../../assets/images/SmartMeter.webp';
import CardImage from '../../components/CardImage/CardImage';
import { CustomUSPCard } from '../../components/CustomUSPCard/CustomUSPCard';
import { OrderFlowPage } from '../../components/OrderFlowPage/OrderFlowPage';
import { useTranslation } from '../../i18n/i18n';
import { ChartsContainer } from './ChartsContainer/ChartsContainer';
import styles from './OfferCalculation.module.css';
import OfferCalculationForm from './OfferCalculationForm/OfferCalculationForm';

const OfferCalculation = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.offerCalculation} data-testid="offer-calculation">
      <OfferCalculationForm />

      <OrderFlowPage title={t('home.title')} className={styles.layoutContainer}>
        <CustomUSPCard i18nScope="home.cardChart">
          <ChartsContainer />
        </CustomUSPCard>

        <CustomUSPCard i18nScope="home.cardSmartMeter">
          <CardImage src={SmartMeter} dataTestId="smart-meter" className={styles.smartMeterImg} />
        </CustomUSPCard>
      </OrderFlowPage>
    </div>
  );
};

export default OfferCalculation;
