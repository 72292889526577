import { Button, Modal, Typography } from '@sonnen/web-ui';
import { parseHtmlTags } from '@sonnen/web-utils';
import { CurrentUserSite, CurrentUserSiteOptionsCreateContractFlow } from '../../graphql/generated';
import { useTranslation } from '../../i18n/i18n';
import styles from './NoTariffPurchaseModal.module.css';

const I18N_SCOPE = 'home.contractModal';

type ContractModalProps = {
  selectedSite: CurrentUserSite | null;
  switchSite?: () => void;
};

const NoTariffPurchaseModal = ({ selectedSite, switchSite }: ContractModalProps) => {
  const { t } = useTranslation();
  const goToWebsite = () => (window.location.href = 'https://sonnen.de');

  let message: string | React.ReactNode = parseHtmlTags(t(`${I18N_SCOPE}.explanationNoSites`));

  if (selectedSite) {
    const { address, options } = selectedSite || {};
    const { streetName, streetNumber, postalCode, city } = address || {};
    const siteAddress = address ? `${streetName} ${streetNumber}, ${postalCode} ${city}` : '';
    const hasFlatXContract =
      options?.createContractFlow ===
      CurrentUserSiteOptionsCreateContractFlow.BlockedDueToFlatXContract;

    message = parseHtmlTags(
      t(`${I18N_SCOPE}.explanation${hasFlatXContract ? 'HasActiveFlatX' : ''}`, {
        siteName: ` ${siteAddress}`,
      }),
    );
  }

  return (
    <Modal dataTestId="no-tariff-purchase-modal" className={styles.noTariffPurchaseModal}>
      <div className={styles.modalContent}>
        <Typography.H3>{t(`${I18N_SCOPE}.title`)}</Typography.H3>

        <span className={styles.explanation}>
          <Typography.Body2 variant="secondary">{message}</Typography.Body2>
        </span>

        {switchSite && (
          <div className={styles.mainBtn}>
            <Button
              onClick={switchSite}
              label={t(`${I18N_SCOPE}.changeSiteBtn`)}
              dataTestId="change-site-btn"
              expanded
            />
          </div>
        )}

        <Button
          onClick={goToWebsite}
          label={t(`${I18N_SCOPE}.toWebsiteBtn`)}
          dataTestId="return-to-website-btn"
          variant={switchSite ? 'secondary' : 'primary'}
          expanded
        />
      </div>
    </Modal>
  );
};

export default NoTariffPurchaseModal;
