import { Typography, useMobile } from '@sonnen/web-ui';
import { useState } from 'react';
import {
  Bar,
  BarChart,
  BarProps,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useAppContext } from '../../../AppContext/AppContext';
import { PriceCards } from '../../../components/PriceCards/PriceCards';
import { useTranslation } from '../../../i18n/i18n';
import { useOfferContext } from '../../../OfferContext/OfferContext';
import { ModuleName } from '../../../types';
import { getTwoDigitsString } from '../../../utils/formatting';
import { getCurrentMonth, getFormattedData, getYAxisProps } from './MonthlyPriceChart.helpers';
import styles from './MonthlyPriceChart.module.css';

type CustomShapeProps = BarProps & {
  x?: number;
  y?: number;
  payload?: { shortMonth: string };
};

export const MonthlyPriceChart = () => {
  const { t } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const { modules } = useAppContext();
  const { offer } = useOfferContext();
  const mobile = useMobile();

  const hasPriceOptimization = modules.has(ModuleName.PRICE_OPTIMIZATION);

  const configuration =
    hasPriceOptimization && offer?.configurationWithBatteryOptimizationModule
      ? offer?.configurationWithBatteryOptimizationModule
      : offer?.configurationWithoutBatteryOptimizationModule;

  const data = configuration?.monthlyExpectedPaymentGrossPerMonthSeries || [];

  const formattedData = getFormattedData(data);
  const selectedData = formattedData.find((d) => d.shortMonth === selectedMonth) ?? null;
  const handleBarClick = (month: string) => setSelectedMonth(month);

  const card = [
    {
      title: selectedData
        ? `<strong>${getTwoDigitsString(selectedData.value).toLocaleString()}</strong> ${t('chart.euroPerMonth')}`
        : '',
      caption: selectedData
        ? t('chart.monthlyPaymentInMonth', {
            monthYear: `${selectedData.longMonth} ${selectedData.year}`,
          })
        : '',
      key: 'monthlyPayment',
    },
  ];

  const yAxisProps = getYAxisProps(data);

  return (
    <div data-testid="monthly-price-chart">
      <PriceCards cards={card} withMargin />
      <div className={styles.monthLabel} data-testid="month-label">
        <Typography.Label2 variant="on-colour">
          {formattedData.find((d) => d.shortMonth === selectedMonth)?.longMonth}{' '}
          {formattedData.find((d) => d.shortMonth === selectedMonth)?.year}
        </Typography.Label2>
      </div>
      <ResponsiveContainer width="100%" height={360}>
        <BarChart
          data={formattedData}
          width={630}
          height={340}
          margin={{ top: 28, right: mobile ? 20 : 40, left: mobile ? -25 : 0 }}
        >
          <defs>
            <linearGradient
              id="colorGradient"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="-13.49%" stopColor="#FB7936" />
              <stop offset="71.63%" stopColor="#0040FA" />
            </linearGradient>
          </defs>
          <CartesianGrid
            stroke="#0A1535"
            strokeOpacity={0.16}
            horizontal={true}
            vertical={false}
            x={0}
            width={1000}
          />

          <XAxis
            dataKey={mobile ? 'firstLetter' : 'shortMonth'}
            tick={({ payload, x, y }) => (
              <text
                x={x}
                y={y + 10}
                textAnchor="middle"
                fontSize={10}
                fill="#0A1535"
                className={
                  payload.value === selectedMonth ? styles.selectedText : styles.unselectedText
                }
              >
                {payload.value}
              </text>
            )}
            tickLine={false}
            axisLine={false}
          />

          <YAxis
            ticks={yAxisProps.ticks}
            tickLine={false}
            domain={yAxisProps.domain}
            label={{
              value: t('chart.monthlyYAxisLabel'),
              fill: '#0A1535',
              opacity: 0.32,
              angle: 0,
              position: 'top',
              dx: 14,
              dy: -4,
              fontSize: 10,
            }}
            tick={{ fill: '#0A1535', opacity: 0.32, dy: 10, fontSize: 10 }}
            axisLine={false}
          />

          <Bar
            dataKey="value"
            barSize={'3%'}
            fill="url(#colorGradient)"
            shape={(props: CustomShapeProps) => {
              const { x = 0, y = 0, width = 0, height = 0, fill, payload } = props;
              const isSelected = payload?.shortMonth === selectedMonth;

              return (
                <g
                  onClick={() => handleBarClick(payload?.shortMonth ?? '')}
                  style={{ cursor: 'pointer' }}
                  data-testid="custom-bar"
                >
                  {isSelected && (
                    <Rectangle
                      x={x - 8}
                      y={y - 8}
                      width={width + 16}
                      height={height + 10}
                      radius={[8, 8, 0, 0]}
                      className={styles.selectedBar}
                    />
                  )}
                  <Rectangle
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={fill}
                    radius={[2, 2, 0, 0]}
                  />
                </g>
              );
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
