import { Typography } from '@sonnen/web-ui';
import { LocaleKey, useTranslation } from '../../../i18n/i18n';
import styles from './ChartLegend.module.css';

export const ChartLegend = ({ localeKey }: { localeKey: LocaleKey }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.chartLegend} data-testid="chart-legend">
      <Typography.Caption1 variant="secondary">{t(localeKey)}</Typography.Caption1>
    </div>
  );
};
