import { Layout, Typography } from '@sonnen/web-ui';
import { useEffect } from 'react';
import { useTranslation } from '../../i18n/i18n';
import { CheckAnimation } from './CheckAnimation/CheckAnimation';
import styles from './Confirmation.module.css';

export const Confirmation = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.confirmationGradient} data-testid="order-complete">
      <Layout centerContent centerVertically backgroundColor="none" fullViewportHeight>
        <div className={styles.animationSection}>
          <CheckAnimation />
          <div className={styles.confirmationText}>
            <Typography.H1>{t('confirmation.congratulation')}</Typography.H1>
            <Typography.H4>{t('confirmation.congratulationMessage')}</Typography.H4>
          </div>
        </div>
      </Layout>
    </div>
  );
};
